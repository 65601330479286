import {mount} from 'svelte';

import SignupForm from './Signup.svelte';

const target = document.getElementById('signup-form');

let signupForm;
if (target) {
    signupForm = mount(SignupForm, {target: target});
}

export default signupForm;
